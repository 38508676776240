<template>
  <v-container fluid fill-height class="px-10" style="background-color: #f5f5f5;">
    <app-header :drawer="false" :flat="true"></app-header>

    <v-row v-if="!loaded" align="center" justify="center">
      <v-col md="12" align="center">
        <v-progress-circular
            class="mt-16"
            :size="50"
            color="primary"
            indeterminate>
        </v-progress-circular>
      </v-col>
      <v-col md="12" align="center">
        <h4>LOADING YOUR PROJECTS</h4>
      </v-col>
    </v-row>

    <v-row v-else>
      <div v-for="project in projects" :key="project.id" class="mr-5 mb-5">
        <v-card class="" tile width="350" height="370" max-height="400" min-width="100" :key="project.id">
          <v-img
              height="200"
              :src="'https://webimages.we2p.de/static/projects/?project_identifier='+project.identifier"
          ></v-img>

          <v-card-title>
            {{ project.name }}
          </v-card-title>

          <v-card-subtitle>
            ID:{{ project.systemId }}
          </v-card-subtitle>

          <v-card-actions>
            <v-btn tile color="primary" :to="'/projects/' + project.id">{{ $t('dashboard.toProject') }}</v-btn>
          </v-card-actions>
        </v-card>
      </div>

      <!--
      <v-col v-for="project in projects" :key="project.id">
        <v-card class="" tile width="350" height="350" min-width="100" :key="project.id">
          <v-img
              height="200"
              :src="'https://webimages.we2p.de/static/projects/?project_identifier='+project.identifier"
          ></v-img>

          <v-card-title>
            {{ project.name }}
          </v-card-title>

          <v-card-subtitle>
            ID:{{ project.systemId }}
          </v-card-subtitle>

          <v-card-actions>
            <v-btn tile color="primary" :to="'/projects/' + project.id">{{ $t('dashboard.toProject') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      -->
    </v-row>
  </v-container>
</template>

<script>
import AppHeader from "@/components/AppHeader";

import {mapActions, mapGetters} from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      loaded: false,
    }
  },
  components: {AppHeader},
  methods: {
    ...mapActions({loadProjects: 'main/loadProjects'})
  },
  computed: {
    ...mapGetters({projects: 'main/getProjects'})
  },
  async mounted() {
    if (this.projects.length) {
      this.loaded = true
    } else {
      await this.loadProjects()
      this.loaded = true
    }
  }
}
</script>